import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import logger from 'ws-scripts/modules/logger';

const LOGGING_PREFIX = 'ws-todays-hours';

/**
 * @desc Utility to log errors to sumo logic. uses ws-scripts logger.error
 * @param {String} errorMsg - Message you want logged
 */
const logError = (errorMsg) => {
	logger.error(`${LOGGING_PREFIX}-Error: ${errorMsg}`);
};

/**
 * @desc Utility to log info to sumo logic, uses ws-scripts logger.info
 * @param {String} msg - Message you want logged
 */
const logInfo = (msg) => {
	logger.info(`${LOGGING_PREFIX}-Error: ${msg}`);
};

/**
 * @desc Utility to log an object to New Relic. Logs to "ws-todays-hours-{id}"
 * @param {String} id - id appended to "ws-todays-hours"
 * @param {*} data - Data object that will get destructured and logged in NR.
 */
const logNRPageAction = (id = '', data = {}) => {
	addNewRelicPageAction(`${LOGGING_PREFIX}-${id}`, { ...data });
};

/**
 * @desc Utility to log a string or number to New Relic. Logs to "ws-todays-hours-{id}"
 * @param {String} id - id appended to "ws-todays-hours"
 * @param {String | number} data - Data string or number that will get logged in NR.
 */
const logNRCustomAttr = (id = '', data) => {
	setNewRelicCustomAttribute(`${LOGGING_PREFIX}-${id}`, data);
};

/**
 * @desc Wrapper for logging functions
 */
export const newRelicLogger = {
	logError,
	logInfo,
	logNRCustomAttr,
	logNRPageAction
};
