import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs } from 'ws-scripts/modules/common';
import { formatContentForDisplay } from '../utilities/formatting';

const Content = ({ hours, label, contentColor }) => {
	const { showOpenTodayUntil } = usePrefs();

	return (
		<span className={setClassNames([contentColor, 'today-hours-content'])}>
			{formatContentForDisplay(showOpenTodayUntil, hours, label)}
		</span>
	);
};

Content.propTypes = {
	hours: PropTypes.string,
	label: PropTypes.string,
	contentColor: PropTypes.string
};

export default Content;
