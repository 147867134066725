import React from 'react';
import { useSelector } from 'react-redux';
import { usePrefs } from 'ws-scripts/modules/common';
import { useLabels } from 'wsm-common-data';
import Icon from '../components/Icon';
import Content from '../components/Content';

const Widget = () => {
	const {
		showIcon,
		iconName,
		iconColor,
		contentColor,
		hoursContainerClasses
	} = usePrefs();
	const hours = useSelector((state) => state.hours);
	const labels = useLabels();

	if (hours) {
		return (
			<div className={`todays-hours ${hoursContainerClasses}`}>
				<Icon
					showIcon={showIcon}
					iconName={iconName}
					iconColor={iconColor}
				/>
				<Content
					hours={hours}
					label={labels.get('TODAY')}
					contentColor={contentColor}
				/>
			</div>
		);
	} else {
		return null;
	}
};

export default Widget;
