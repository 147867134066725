/* eslint-disable no-console */
import { DAYS_OF_WEEK } from '../global-constants';
import { newRelicLogger } from './newRelicLogger';

export const getCurrentDayName = () => {
	// We shift the array by one, since our week starts at Monday
	const dayIndex =
		(new Date().getDay() + DAYS_OF_WEEK.length - 1) % DAYS_OF_WEEK.length;

	return DAYS_OF_WEEK[dayIndex];
};

/**
 * This function validates if a string is in the "hh:mm am/pm" format.
 *
 * @param {string} timeStr - The string to be validated.
 * @returns {boolean} - Returns true if the string is in the "hh:mm am/pm" format, false otherwise.
 */

export const validateTimeFormat = (timeStr) => {
	const timeFormat = /^([01]?[0-9]|2[0-3]):[0-5][0-9] (am|pm)$/i;
	return timeFormat.test(timeStr.trim());
};

/**
 * This function formats a time string into an array of two elements.
 * If the time string is "Closed", it returns an array with two "Closed" strings.
 *
 * @param {string} hours - A string representing time in "hh:mm am/pm - hh:mm am/pm" or "Closed" format.
 * @returns {Array} - An array of two strings representing the opening and closing times.
 */

export const formatHours = (hours) => {
	const hoursTime = hours.trim();

	if (hoursTime.toLowerCase() === 'closed') {
		return ['Closed', 'Closed'];
	}

	const normalizeTime = (time) => {
		// Add a space between minutes and period (AM/PM) if it doesn't exist already
		let timing = time.replace(/(\d{1,2}:\d{2})(am|pm)/i, '$1 $2').trim();

		// Replace multiple spaces between minutes and period with a single space
		timing = timing.replace(/\s{2,}/, ' ');
		return timing;
	};

	return hours.split('-').map(normalizeTime);
};

/**
 * This function converts a time string into a Date object.
 * If no argument is passed, it returns the current date and time.
 *
 * @param {string} time - A string representing time in "hh:mm am/pm" format.
 * @returns {Date} - A Date object representing the given time on the current date.
 * @throws {Error} - Will throw an error if the time string is not in the expected format.
 */

export const convertTimeStringToDate = (time = null) => {
	if (time === null) return new Date();
	const date = new Date();

	const [hours, minutes, period] = time.trim().split(/[:\s]/);
	let mins = 0;
	if (minutes) {
		mins = parseInt(minutes, 10);
	}
	if (!period) {
		// adding a NR custom attribute to keep track of nonstandard formats that might be used
		newRelicLogger.logNRCustomAttr('WrongFormatHours', time);

		throw new Error(
			'Time must be in the format "hh:mm am/pm" or "hh am/pm"'
		);
	}

	const hoursCalculation =
		(hours % 12) + (period.toLowerCase() === 'pm' ? 12 : 0);
	date.setHours(hoursCalculation);
	date.setMinutes(mins);

	return date;
};

/**
 * Formats a given time into a 12-hour format with an AM/PM indicator.
 *
 * @param {Date} time - The time to be formatted.
 * @returns {string} The formatted time in the format of "HH AM/PM".
 *
 * @example
 * // returns "5 pm"
 * formatTime(new Date('2024-05-06T17:00:00'));
 */

export const formatTime = (time, includeMinutes = false) => {
	const hours = time.getHours();
	const minutes = includeMinutes
		? (time.getMinutes() < 10 ? '0' : '') + time.getMinutes()
		: '';
	const twelveHourFormat = hours % 12 || 12;
	const period = hours >= 12 ? 'pm' : 'am';
	return `${twelveHourFormat}:${minutes} ${period}`;
};

/**
 * This function generates a message indicating whether a place is currently open, closed, or will open tomorrow.
 * It takes in three arguments representing the opening time, closing time, and current time.
 * The opening time and closing time can either be Date objects or the string "Closed".
 *
 * @param {String} showOpenTodayUntil - A string representation of 'true' or 'false'.
 * @param {Date|string} openingTime - A Date object or the string "Closed" representing the opening time.
 * @param {Date|string} closingTime - A Date object or the string "Closed" representing the closing time.
 * @param {Date} currentTime - A Date object representing the current time.
 * @param {String} label - label represents a string message added by the user.
 * @returns {string} - A string message indicating whether the place is currently open, closed, or will open tomorrow.
 * @throws {Error} - Will throw an error if any of the arguments are not of type Date or the string "Closed".
 */

export const formatBusinessHoursMessage = (
	showOpenTodayUntil,
	openingTime,
	closingTime,
	currentTime,
	label
) => {
	if (
		(typeof openingTime === 'string' &&
			openingTime.trim().toLowerCase() === 'closed') ||
		(typeof closingTime === 'string' &&
			closingTime.trim().toLowerCase() === 'Closed')
	) {
		return 'Closed today';
	}

	if (
		typeof showOpenTodayUntil !== 'string' ||
		!(openingTime instanceof Date) ||
		!(closingTime instanceof Date) ||
		!(currentTime instanceof Date) ||
		typeof label !== 'string'
	) {
		newRelicLogger.logNRCustomAttr('WrongDataType', {
			openingTime,
			closingTime,
			currentTime
		});
		throw new Error(
			'Not passing the correct data types through the arguments'
		);
	}

	// Default message for Kia and any other account that would like to use our widget.
	let message = `${label}: ${formatTime(openingTime, true)} - ${formatTime(
		closingTime,
		true
	)}`;

	if (
		currentTime.getHours() >= openingTime.getHours() &&
		currentTime.getHours() <= closingTime.getHours() &&
		showOpenTodayUntil === 'true'
	) {
		message = `Open today until ${formatTime(closingTime, true)}`;
	}

	return message;
};
