import {
	convertTimeStringToDate,
	formatBusinessHoursMessage,
	validateTimeFormat
} from './time';

/**
 * This function formats the content that will be displayed by the content widget.
 * If hours is a string, the content is nonstandard format and is displayed outright.
 * If hours is an object, other logic will have split the input opening and closing times
 * or set Closed for each key if that is the case.
 *
 * @param {string} showOpenTodayUntil - A string representing 'true' or 'false'.
 * @param {string || object} hours - In the case of nonstandard data a string, or in a normal
 * case an object with two properties: openingTime and closingTime.
 * @param {string} label - The label to insert at the beginning of the display content.
 * @returns {string} - The formatted time in string format to be displayed by the widget.
 */
export const formatContentForDisplay = (showOpenTodayUntil, hours, label) => {
	if (typeof hours === 'string') {
		return `${label}: ${hours}`;
	} else {
		return formatBusinessHoursMessage(
			showOpenTodayUntil,
			validateTimeFormat(hours.openingTime)
				? convertTimeStringToDate(hours.openingTime)
				: hours.openingTime,
			validateTimeFormat(hours.closingTime)
				? convertTimeStringToDate(hours.closingTime)
				: hours.closingTime,
			convertTimeStringToDate(),
			label
		);
	}
};
