import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'ws-scripts/modules/common';

const Icon = ({ showIcon, iconName, iconColor }) => {
	const { iconAdditionalClasses } = usePrefs();

	if (showIcon === 'true' && iconName !== '') {
		return (
			<i
				className={`ddc-icon today-hours-icon ddc-icon-${iconName} ${iconColor} ${iconAdditionalClasses}`}
				aria-hidden="true"
				data-testid="icon"
				role="presentation"
			/>
		);
	} else {
		return null;
	}
};

Icon.propTypes = {
	showIcon: PropTypes.bool.isRequired,
	iconName: PropTypes.string.isRequired,
	iconColor: PropTypes.string
};

export default Icon;
